.footer-copyright{
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    font-size: xx-small;
    text-align: left;
}
