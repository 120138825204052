.App {
  margin-top: 15px;
  max-width: 1500px;
  position: relative;
  min-height: 92vh;
}

.App .navbar-brand {
  font-weight: bold;
}

.navbar-nav > li {
  float: left;
  position: relative;
}

/*.grid-wrapper {*/
/*  height: -webkit-calc(30vh - 72px);*/
/*  height: -moz-calc(100vh - 72px);*/
/*  height: calc(30vh - 72px);*/
/*  !*background-color: rgba(90,90,190,0.8);*!*/
/*}*/

/*.navbar-inner {*/
/*  background:transparent;*/
/*}*/

/*.navbar-light .navbar-nav .active a::after {*/
/*  border-bottom: 5px solid #5bc0eb;*/
/*  bottom: -10px;*/
/*  content: " ";*/
/*  left: 0;*/
/*  position: absolute;*/
/*  right: 0;*/
/*}*/