.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .pumps h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

.Home .lander div {
    padding-top: 20px;
}

/*.Days .lander div a:first-child {*/
/*  margin-right: 20px;*/
/*}*/

.Home .pumps h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    overflow: hidden;
    line-height: 1.5;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Home .pumps p {
    color: #666;
}

.Home Button {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
}

.Home .button-duplicate {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.Home .button-info {
    margin-bottom: 5px;
    margin-top: 0;
    padding-left: 10px;
    margin-right: 0;
    margin-left: 20px;
}

.Home .page-size-header {
    font-weight: 300;
    font-size: smaller;
    margin-right: 15px;
}

.Home input {
    font-weight: 300;
    font-size: smaller;
}

.Home .page-container {
    margin-bottom: 8px;
}

.Home .pump-container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.rag-red {
    background-color: lightcoral;
}

.rag-green {
    background-color: lightgreen;
}

.rag-amber {
    background-color: lightgreen;
}

.my-red {
    background-color: #F2DEDE;
}

.my-amber {
    background-color: #FCF8E3;
}


.not-visible {
    visibility: hidden;
}

/*.ag-row-hover {*/
/*    !* putting in !important so it overrides the theme's styling as it hovers the row also *!*/
/*    background-color: #dfdfff !important;*/
/*}*/